import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './media.css';

export const MediaBox = ({ name, svg, zip, theme = 'light' }) => (
  <Container className={'media--' + theme}>
    <div
      className="media__body"
      style={{ background: 'url(' + svg + ') no-repeat center center' }}
    ></div>
    <div className="media__footer">
      <span className="media__text">{name}</span>
      <span className="media__download">
        <a href={zip}>Download</a>
      </span>
    </div>
  </Container>
);

export const MediaBrandBox = ({ name, hex }) => (
  <Container className="media--brand media--light">
    <div className="media__body" style={{ background: hex }}></div>
    <div className="media__footer">
      <span className="media__text">{name}</span>
      <span className="media__hex" style={{ color: hex }}>
        {hex}
      </span>
    </div>
  </Container>
);

MediaBox.propTypes = {
  name: PropTypes.string,
  svg: PropTypes.string,
  zip: PropTypes.string,
  theme: PropTypes.string,
};

MediaBrandBox.propTypes = {
  name: PropTypes.string,
  hex: PropTypes.string,
};
