import styled from 'styled-components';
import { space, secondary, transition } from 'constants/theme';

export const Container = styled.div`
  border-radius: 10px;

  &.media--dark {
    border: 1px solid #4f4f4f;
    background: #4f4f4f;

    .media__footer {
      border-top: 1px solid #828282;
    }

    .media__text {
      color: #bdbdbd;
    }
  }

  &.media--light {
    border: 1px solid #f2f2f2;

    .media__footer {
      border-top: 1px solid #f2f2f2;
    }
  }

  .media__body {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
    border-radius: 10px 10px 0 0;
  }

  .media__footer {
    display: flex;
    justify-content: space-between;
    align-content: space-around;
    align-items: center;
    padding: ${space / 4}px ${space / 2}px;
  }

  .media__text {
    font-size: 12px;
    line-height: 14px;
  }

  .media__download a {
    color: ${secondary};
    font-size: 12px;
    line-height: 0;
    text-decoration: none;
    padding: 2px 15px;
    border-radius: 50px;
    border: 1px solid ${secondary};
    transition: all ${transition}s;

    &:hover,
    &:active,
    &:focus {
      color: #fff;
      background: ${secondary};
    }
  }

  .media__hex {
    display: block;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
  }

  &.media--brand {
    .media__footer {
      display: block;
    }

    .media__text {
      display: block;
      color: #333;
      font-size: 15px;
      font-weight: 500;
      line-height: 18px;
    }
  }

  &.media--brand .media__body {
    min-height: 150px;
  }
`;
